import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationServiceRESTRoleService, AuthorizationServiceRESTUserService, ReadUser, ResetPassword } from '../authorization/generated';

@Injectable({ providedIn: 'root' })
export class UserDataService extends DefaultDataService<ReadUser> {
    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private userApi: AuthorizationServiceRESTUserService,
        private roleApi: AuthorizationServiceRESTRoleService
    ) {
        super('ReadUser', http, httpUrlGenerator);
    }

    getWithQuery(queryParams: QueryParams | string): Observable<ReadUser[]> {
        if (queryParams['companyCode']) {
            return this.userApi.getAllUsers(queryParams['companyCode']);
        }

        if (queryParams['companyCode'] && queryParams['roleId']) {
            return this.roleApi.getRoleUsers(queryParams['companyCode'], queryParams['roleId']);
        }
    }

    getById(username: string): Observable<ReadUser> {
        return this.userApi.getUserForName('company', username);
    }

    resetCredentials(company: string, username: string, resetPassword?: ResetPassword): Observable<number> {
        return this.userApi.resetCredentials(company, username, resetPassword);
    }

    deleteUserInfoInSSO(company: string, username: string): Observable<any> {
        return this.userApi.resetFederatedUser(company, username);
    }
}
