import { Injectable } from '@angular/core';
import { ResetUserPasswordDialogComponent } from '@shared/reset-user-password-dialog/reset-user-password-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { ContextSnackbarService } from '@shared/services/context-snackbar.service';
import { UserDataService } from '@app/user-manager/shared/api/services/user-data.service';
import { ReadUser } from '@app/user-manager/shared/api/authorization/generated';
import { User } from '@app/user-manager/shared/api/authorization/generated/model/user';

@Injectable()
export class ResetUserPasswordService {
    constructor(private dialog: MatDialog, private contextSnackbar: ContextSnackbarService, private usersData: UserDataService) {}

    public openResetPasswordDialog(user: ReadUser | User, company: string): void {
        const dialogRef = this.dialog.open(ResetUserPasswordDialogComponent, <MatDialogConfig>{
            data: {
                user: user
            },
            disableClose: true
        });
        dialogRef
            .afterClosed()
            .pipe(filter(next => !!next))
            .subscribe(password => {
                this.usersData
                    .resetCredentials(company, user.username, password)
                    .pipe(take(1))
                    .subscribe(
                        d => {
                            this.contextSnackbar.showNotification('success', `New password updated successfully.`);
                        },
                        e => {
                            const eMsg = e.error?.message;
                            this.contextSnackbar.showNotification(
                                'error',
                                `We couldn't reset ${user.username} password. ${eMsg ?? 'Please, try again later.'}`
                            );
                        }
                    );
            });
    }
}
