<div class="password__container">
    <h1 mat-dialog-title class="password__title">Reset Password</h1>
    <div mat-dialog-content>
        <div [formGroup]="passwordForm" class="password__content">
            <sphere-form-field *ngIf="hideOldPasswordField" label="CURRENT PASSWORD" [required]="true" [errorMessage]="getErrorMessage(oldPassword)">
                <mat-icon dronaPrefix>vpn_key</mat-icon>
                <input dronaInput [type]="hideOldPassword ? 'password' : 'text'" formControlName="oldPassword" data-cy="current-password" />
                <mat-icon [style.cursor]="'pointer'" dronaSuffix (click)="toggleHideOldPassword()" [style.margin-bottom.px]="7">{{
                    !hideOldPassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </sphere-form-field>
            <sphere-form-field label="NEW PASSWORD" [required]="true" [errorMessage]="getErrorMessage(password)">
                <mat-icon dronaPrefix>vpn_key</mat-icon>
                <input dronaInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" data-cy="new-password" />
                <mat-icon [style.cursor]="'pointer'" dronaSuffix (click)="toggleHidePassword()" [style.margin-bottom.px]="7">{{
                    !hidePassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </sphere-form-field>
            <sphere-form-field label="CONFIRM PASSWORD" [required]="true" [errorMessage]="getErrorMessage(confirmPassword)">
                <mat-icon dronaPrefix>vpn_key</mat-icon>
                <input dronaInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword" data-cy="confirm-password" />
                <mat-icon [style.cursor]="'pointer'" dronaSuffix (click)="toggleHideConfirmPassword()" [style.margin-bottom.px]="7">{{
                    !hideConfirmPassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
            </sphere-form-field>
        </div>
    </div>
    <div mat-dialog-actions [align]="'end'">
        <button mat-button (click)="close()" [style.margin-right.px]="10" data-cy="reset-password-cancel-btn">CANCEL</button>
        <button mat-flat-button color="primary" [disabled]="!passwordForm.valid" (click)="save()" data-cy="reset-password-save-btn">SAVE</button>
    </div>
</div>
